<template>
    <div class="mine">
        <Head :title="$t('common.mine')">
            
        </Head>

        <div class="main-box" v-if="dashboard">
            <div class="mine-top">
                <div class="mine-top-left">
                    <van-image round :src="user.headimgurl" class="mine-avatar"></van-image>
                    <div class="mine-user">
                        <div class="user-info">
                            <h4 class="van-ellipsis">{{dashboard.username}}</h4>
                            <p class="">{{dashboard.rankname}}</p>
                        </div>
                        <div class="user-id">
                            ID:<span>{{dashboard.uid}}</span>
                        </div>
                    </div>
                </div>
                <div class="mine-top-right">
                    <van-button class="right-btn-qian" @click="gotowhere('mine-qiandao')"  >{{$t('mine.qiandao')}}</van-button>
                    <van-button class="right-btn-bang" @click="gotowhere('mine-bang')"  >{{$t('mine.bang')}}</van-button>
                </div>
                
            </div>
            <div class="mine-middle">
                <div class="mine-middle-item" @click="gotowhere('mine-info')">
                    <img class="img1" src="../../assets/imgs/huiyuan.png" alt="">
                    <span :class="lang=='en'?'spanen':''">{{$t('mine.myinfo')}}</span>
                </div>
                <div class="mine-middle-item" @click="goAuction">
                    <img class="img2" src="../../assets/imgs/myauction.png" alt="">
                    <span>{{$t('mine.myauction')}}</span>
                </div>
                <div class="mine-middle-item" @click="gotowhere('mine-score')">
                    <img class="img3" src="../../assets/images/myfen.png" alt="">
                    <span>{{$t('common.mypv')}}</span>
                </div>
            </div>
            <div class="mine-content">
                <van-cell-group class="mine-content-group">
                    <van-cell class="mine-content-cell"  @click="gotoNewtry('mine-newtry')"  >
                        <template #title>
                            <div class="mine-content-left">
                                <p class="p-three">
                                    <img src="../../assets/images/jingpai.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.newer')}}</span>
                            </div> 
                        </template>
                        <template #extra >
                            <p class="new-extra"  >
                                <template v-if="user.is_try_bonus==1">
                                    <img src="../../assets/images/tiyan.png" alt="">
                                    <span>{{$t('wallet.giveaway')}} 3 Bid Token</span>
                                </template>
                                <template v-else>
                                    <i class="arc heartBeat"></i>
                                    <span>{{$t('wallet.giveaway')}} 3 Bid Token</span>
                                </template>
                                <van-icon name="arrow"></van-icon>
                            </p>
                        </template>
                    </van-cell>
                    <van-cell @click="gotowhere('mine-juan')" class="mine-content-cell" :value="$t('mine.get_from_market')" is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-one">
                                    <img src="../../assets/imgs/quan.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.dianzijuan')}}</span>
                            </div> 
                        </template>
                    </van-cell>

                    <van-cell :to="{name:'mine-need'}" class="mine-content-cell"  is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-two">
                                    <img src="../../assets/imgs/xuqiu.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.xuqiudan')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                    
                    <van-cell :to="{name:'mine-qrcode'}" class="mine-content-cell"  is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-four">
                                    <img src="../../assets/imgs/myqrcode.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.mycode')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                    <van-cell  :to="{name:'mine-jiang'}"  class="mine-content-cell"  is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-five">
                                    <img src="../../assets/imgs/jiang.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.mysalaary')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                    <van-cell :to="{name:'mine-team'}" class="mine-content-cell"  is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-six">
                                    <img src="../../assets/imgs/team.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.myteam')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                    <van-cell :to="{name:'mine-help'}" class="mine-content-cell"  is-link >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-seven">
                                    <img src="../../assets/imgs/help.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('mine.helpme')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                    <van-cell  @click="showPopup"  class="mine-content-cell" :value="lastLang | langtext" is-link  >
                         <template #title>
                            <div class="mine-content-left">
                                <p class="p-seven">
                                    <img src="../../assets/imgs/lang.png" alt="">
                                </p>
                                <span class="custom-title">{{$t('add.language')}}</span>
                            </div> 
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
            <van-button class="mine-logot" @click="logoutAction">{{$t('mine.logout')}}</van-button>
        </div>

        <van-popup class="login-popup" v-model="show" position="bottom"  :style="{ height: '5.3333rem' }" duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('login.set_lang')}}</p>
                    <van-icon name="cross" class="pop-close" @click="show=false" />
                </div>
                <div class="pop-btns">
                    <van-button class="btn-normal" @click="changeLang('cn')" :class="lang=='cn'?'btn-active':''" >中文</van-button>
                    <van-button class="btn-normal"  @click="changeLang('th')" :class="lang=='th'?'btn-active':''">ไทย</van-button>
                    <van-button class="btn-normal"  @click="changeLang('en')" :class="lang=='en'?'btn-active':''">English</van-button>
                </div>
                <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
            </div>
        </van-popup>
    </div>
</template>


<script>
import {myinfo} from "@/common"
import {vantLocales} from "@/main.js"
import router from "@/router"
import {mapState,mapMutations,mapGetters} from "vuex"
export default {
    // mixins:[myinfo],
    name:"main-mineIndex",
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            lastLang:localStorage.getItem("langData") || 'th',
            show:false,
        }
    },
    // beforeRouteEnter(to, from, next) {    //判断是否登录
    //     var access_token = localStorage.getItem("access_token");
    //     if(access_token){
    //         next()
    //     }else{
    //         router.push({name:'signin'})
    //     }
    // },
    mounted(){
        this.$ajax.dashboard()
        .then(res=>{
            this.setdashboard(res.data);
            localStorage.setItem('dashboard',JSON.stringify(res.data))
        })
    },
    computed:{
        ...mapState(['dashboard']),
        ...mapGetters(['user']),
    },
    methods:{
        ...mapMutations(['setdashboard','setCardList']),
        showPopup(){
            this.lang = localStorage.getItem("langData") || 'th',
            this.show = true;
        },
        changeLang(lang){
            this.lang = lang;
        },
        sureChange(){
            localStorage.setItem('langData',this.lang);
            this.$i18n.locale = this.lang;
            this.lastLang = this.lang;
            this.show = false;
            vantLocales(this.lang)
            this.$ajax.dashboard()
            .then(res=>{
                this.setdashboard(res.data);
                localStorage.setItem('dashboard',JSON.stringify(res.data))
            })
        },
        logoutAction(){   // 退出登录
            this.$ajax.logout()
            .then(res=>{
                if(res.code==200){
                    localStorage.removeItem("access_token");
                    localStorage.removeItem('dashboard');
                    localStorage.removeItem('bannercount');
                    this.setdashboard(null);
                    this.setCardList(null);
                    this.$router.replace({path:"/signin",query:{_t:new Date().getTime().toString()}})
                }
            })
        },
        goAuction(){
            this.$router.push({
                name:'mine-myAuction'
            })
        },
        gotowhere(name){
            this.$router.push({
                name
            })
        },
        gotoNewtry(name){
            this.$router.push({
                name,
                query:{
                    isnew:1 , // 新手
                    is_try_bonus:this.user.is_try_bonus
                }
            })
        }
    }
}
</script>